const state = () => ({
    all: {
        lotsStatusMap: [],
        billingStatusMap: [],
        billingOperationsMap: [],        
    }
})
// getters
const getters = {
    getCacheByName: (state) => (name) => {
        return state.all[name]
    }
}
// actions
const actions = {
    async setCache(context, object) {
        context.commit('SET_CACHE', object)
    }
}

// mutations
const mutations = {
    SET_CACHE(state, object) {
        state.all[object.key] = object.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}