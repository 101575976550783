<template>
  <div class="lot-root">
    <div class="ref-procedures-crm-block">
      <div style="height: 41em;">
        <div v-if="loadingRefProceduresCrm" class="loader">
          <mo-loader />
        </div>
        <mo-table v-else :columns="columnsRefProceduresCrm" :items="itemsRefProceduresCrm"
          v-model:current="currentItemRefProceduresCrm" />
      </div>
      <div class="panel">
        <mo-paginator-2 v-bind:currentPage="currentPageRefProceduresCrm" :maxPage="maxPageRefProceduresCrm"
          @pageChange="pageChangeRefProceduresCrm" />
      </div>
    </div>
  </div>
</template>

<script>
const columnsRefProceduresCrm = [
  { name: "id", title: "№", width: 100 },
  { name: "title", title: "Наименование", width: 100 },
  { name: "inserted_at", title: "Дата", width: 100, type: "DateTime" },
  { name: "creator_company", title: "Компания", width: 100 },
  { name: "status", title: "Статус", width: 100 },
];


export default {
  name: 'call-center-tab3',
  props: {
    companyItem: {
      type: Object,
    }
  },
  data() {
    return {
      //Переменные для верхней таблицы пользоатели компании
      columnsRefProceduresCrm,
      itemsRefProceduresCrm: [],
      loadingRefProceduresCrm: false,
      currentPageRefProceduresCrm: 0,
      maxPageRefProceduresCrm: false,
      limitRefProceduresCrm: 17,
      dateBeginRefProceduresCrm: undefined,
      dateEndRefProceduresCrm: undefined,
      queryRefProceduresCrm: "",
      opTypeRefProceduresCrm: "",
      currentItemRefProceduresCrm: [],
      statusLotsMap: [],
      //offsetRefProceduresCrm: 0,
    }
  },
  methods: {
    async pageChangeRefProceduresCrm(event) {
      this.currentPageRefProceduresCrm = event;
      this.loadRefProceduresCrm();
    },
    decodeStatus() {
      let d = this.itemsRefProceduresCrm;
      d.forEach((item, i) => {
        d[i]['status'] = this.statusLotsMap[item['status']] || item['status'];
      })
      this.itemsRefProceduresCrm = d;
    },
    async loadRefProceduresCrm() {
      this.loadingRefProceduresCrm = true;

      const { success, message, data: responseData } = await this.$http.getRefProceduresCrmById(
        this.companyItem.Id,
        this.limitRefProceduresCrm,
        this.currentPageRefProceduresCrm * 17,
      );

      let d = responseData?.result || [];
      d.forEach((item, i) => {
        d[i]['status'] = this.statusLotsMap[item['status']] || item['status'];
      })

      this.itemsRefProceduresCrm = d;

      if (this.itemsRefProceduresCrm.length > 0) this.maxPageRefProceduresCrm = false;
      if (this.itemsRefProceduresCrm.length < 17) this.maxPageRefProceduresCrm = true;

      this.loadingRefProceduresCrm = false;
    },
    async loadStatusLots() {
      const { success, message, data: responseData } = await this.$http.getRefStatusLots();
      if (success) {
        let d = responseData || [];
        let b = [];
        
        d.forEach(e => {
          b[e['Code']] = e['Name'];
        });

        this.statusLotsMap = b;
        this.decodeStatus();
      }
    },
  },
  mounted() {
    this.loadStatusLots();
    this.loadRefProceduresCrm();
  },
}
</script>

<style lang="scss" scoped>
.lot-root {
  width: 100%;
  height: 100%;

  .ref-procedures-crm-block {
    width: 100%;
    height: 100%;
    @include flex(column, flex-start, stretch);

    .loader {
      padding: 50px 0;
      @include flex();
    }

    .panel {
      top: 90%;
      @include flex(column, space-between, right);
    }
  }
}
</style>
