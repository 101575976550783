<template>
  <div class="mo-select-root" :class="{ small: size === 'small' }">
    <select
      v-model="selected"
      @change="onInput"
      :placeholder="placeholder"
      @focus="focused = true"
      @blur="focused = false"
      :disabled="disabled"
    >
      <option v-for="option in preparedOptions" :key="option.text" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <div class="gray-border" />
    <div class="blue-border" :class="{ focused }" />
    <div v-if="help" class="help" @click="toggleHelp()" v-click-outside="closeHelp">
      <mo-icon name="help" color="white" />
      <div :class="{ active: showHelp }">{{ help }}</div>
    </div>
    <div v-if="error" class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "mo-select",
  model: {
    prop: "modelValue",
  },
  emits: ["update:modelValue"],
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    error: {
      type: String,
    },
    help: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showHelp: false,
      focused: false,
      selected: this.modelValue,
    };
  },
  computed: {
    preparedOptions() {
      return this.options.map((item) =>
        typeof item === "object" ? item : { value: item, text: item }
      );
    },
  },
  methods: {
    closeHelp() {
      this.toggleHelp(false);
    },
    toggleHelp(newState = !this.showHelp) {
      this.showHelp = newState;
    },
    onInput(_newValue) {
      this.$emit("update:modelValue", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-select-root {
  @include flex(column, flex-start, stretch);
  position: relative;
  min-width: 300px;
  margin: 0 0 10px 0;
  &.small {
    min-width: 100px;
  }
  > select {
    outline-width: 0;
    outline-color: transparent;
    border-radius: 0;
    background: transparent;
    color: #2c3e50;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 25px 5px 5px;
    margin: 0;
    border: none;
    transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    &:disabled {
      background-color: #eaeaea;
      cursor: not-allowed;
    }
  }
  option {
    background-color: #fafafa;
    color: #2c3e50;
    font-size: 16px;
  }
  > .gray-border {
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
    right: 0;
    height: 1px;
    border: none;
    background-color: #aaaaaa;
  }
  > .blue-border {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 50%;
    right: 50%;
    height: 1px;
    border: none;
    background-color: #008cdb;
    transition: left 1s ease, right 1s ease;
    &.focused {
      left: 0;
      right: 0;
    }
  }
  > .help {
    position: absolute;
    right: 0px;
    font-size: 16px;
    line-height: 24px;
    z-index: 2;
    > svg {
      cursor: pointer;
      border-radius: 50%;
      background-color: #008cdb;
      vertical-align: middle;
    }
    > div {
      min-width: 240px;
      @include flex();
      display: none;
      position: absolute;
      right: 100%;
      top: 0;
      margin-right: 5px;
      margin-top: 5px;
      padding: 10px;
      background-color: lighten(#aaaaaa, 30%);
      color: #2c3e50;
      border: 1px solid lighten(#aaaaaa, 20%);
      border-radius: 5px;
      font-size: 16px;
      line-height: 18px;
      &.active {
        display: flex;
      }
    }
  }
  .error {
    position: absolute;
    bottom: -10px;
    left: 5px;
    min-height: 12px;
    font-size: 12px;
    line-height: 12px;
    color: #ca3333;
  }
}
</style>
